.SettingsBtn {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: 20px;
}

.SettingsBtn button {
  width: 25px;
  padding: 3px;
  border-radius: 5px;
  outline: none;
  border: none;
  background-color: transparent;
  color: var(--background-color);
  fill: currentColor;
  filter: invert(1) grayscale(1);
  cursor: pointer;
}
